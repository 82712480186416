import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import axiosInstance from "../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import BalanceChart from "./BalanceChart";
import { format, subHours, subWeeks, subMonths } from "date-fns";
import { es } from "date-fns/locale";

const Admin = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [editingUsers, setEditingUsers] = useState({});
  const [updateLoading, setUpdateLoading] = useState({});
  const [showPasswordFields, setShowPasswordFields] = useState({});
  const [expandedUser, setExpandedUser] = useState(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState("24h");
  const [hoverData, setHoverData] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showChartModal, setShowChartModal] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState("");
  const [newUser, setNewUser] = useState({
    country: "",
    name: "",
    surname: "",
    phone: "",
    personId: "",
    email: "",
    password: "",
    role: "user",
    wallet: "",
    initialInvestment: 0,
    initialInvestmentDate: new Date().getTime(),
  });

  const handleViewChart = (userData) => {
    setSelectedUser(userData);
    setShowChartModal(true);
  };
  const handleCreateChange = (e) => {
    const value = e.target.type === "number" ? parseFloat(e.target.value) : e.target.value;
    setNewUser((prev) => ({
      ...prev,
      [e.target.name]: value,
    }));
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setCreateLoading(true);
    setCreateError("");

    try {
      await axiosInstance.post("/v1/users", newUser);
      setNewUser({
        country: "",
        name: "",
        surname: "",
        phone: "",
        personId: "",
        email: "",
        password: "",
        role: "user",
        wallet: "",
        initialInvestment: 0,
      });
      setShowCreateForm(false);
      await fetchUsers();
    } catch (err) {
      setCreateError(err.response?.data?.message || "Error creating user");
    } finally {
      setCreateLoading(false);
    }
  };

  useEffect(() => {
    if (user?.role !== "admin") {
      navigate("/dashboard");
      return;
    }
    fetchUsers();
  }, [user, navigate]);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get("/v1/users");
      setUsers(response.data.data);

      const initialEditingState = {};
      response.data.data.forEach((user) => {
        initialEditingState[user._id] = {
          name: user.name || "",
          surname: user.surname || "",
          email: user.email || "",
          wallet: user.wallet || 0,
          initialInvestment: user.initialInvestment || 0,
          withdrawalPending: user.withdrawalPending || false,
          password: "", // Add empty password field
          initialInvestmentDate: user.initialInvestmentDate || new Date().getTime(),
        };
      });
      setEditingUsers(initialEditingState);
    } catch (err) {
      setError("Error fetching users");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (userId, field, value) => {
    setEditingUsers((prev) => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        [field]: value,
      },
    }));
  };

  const togglePasswordField = (userId) => {
    setShowPasswordFields((prev) => ({
      ...prev,
      [userId]: !prev[userId],
    }));
    // Reset password field when hiding it
    if (showPasswordFields[userId]) {
      handleInputChange(userId, "password", "");
    }
  };

  const handleUpdate = async (userId) => {
    setUpdateLoading((prev) => ({ ...prev, [userId]: true }));
    try {
      const updatedData = editingUsers[userId];
      const originalUser = users.find((u) => u._id === userId);

      const changedFields = {};
      Object.keys(updatedData).forEach((key) => {
        // Include password in changedFields only if it's not empty
        if (key === "password") {
          if (updatedData[key]) {
            changedFields[key] = updatedData[key];
          }
        } else if (updatedData[key] !== originalUser[key]) {
          changedFields[key] = updatedData[key];
        }
      });

      if (Object.keys(changedFields).length === 0) {
        return;
      }

      await axiosInstance.put(`/v1/users/${userId}`, changedFields);
      await fetchUsers();
      // Reset password field and hide it after successful update
      handleInputChange(userId, "password", "");
      setShowPasswordFields((prev) => ({ ...prev, [userId]: false }));
      setError("");
    } catch (err) {
      setError(`Error updating user: ${err.response?.data?.message || err.message}`);
    } finally {
      setUpdateLoading((prev) => ({ ...prev, [userId]: false }));
    }
  };

  if (loading) {
    return (
      <>
        <Navbar />
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
          <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-indigo-600"></div>
        </div>
      </>
    );
  }

  const toggleChart = (userId) => {
    setExpandedUser(expandedUser === userId ? null : userId);
  };
  const calculateStats = (userData) => {
    if (!userData?.balance || userData.balance.length === 0) {
      return {
        currentBalance: 0,
        periodChange: 0,
        changePercentage: 0,
        totalChangePercentage: 0,
        totalChange: 0,
      };
    }

    const sortedBalance = [...userData.balance].filter((bal) => bal.wallet.toLowerCase() === userData.wallet.toLowerCase()).sort((a, b) => a.timestamp - b.timestamp);

    if (sortedBalance.length === 0) {
      return {
        currentBalance: 0,
        periodChange: 0,
        changePercentage: 0,
        totalChangePercentage: 0,
        totalChange: 0,
      };
    }

    const currentBalance = sortedBalance[sortedBalance.length - 1].totalUSD;
    const balanceToCompare = hoverData ? hoverData.value : currentBalance;

    const initialInvestment = userData.initialInvestment || 0;
    const totalChange = balanceToCompare - initialInvestment;
    const totalChangePercentage = (totalChange / initialInvestment) * 100;

    let periodStartTimestamp;
    switch (selectedTimeRange) {
      case "24h":
        periodStartTimestamp = subHours(new Date(), 24).getTime();
        break;
      case "1w":
        periodStartTimestamp = subWeeks(new Date(), 1).getTime();
        break;
      case "1m":
        periodStartTimestamp = subMonths(new Date(), 1).getTime();
        break;
      default:
        periodStartTimestamp = subHours(new Date(), 24).getTime();
    }

    const periodData = sortedBalance.filter((b) => b.timestamp >= periodStartTimestamp);
    console.log(periodStartTimestamp);

    let periodChange = 0;
    let changePercentage = 0;

    if (periodData.length >= 2) {
      periodChange = currentBalance - periodData[0].totalUSD;
      changePercentage = (periodChange / periodData[0].totalUSD) * 100;
    }

    return {
      currentBalance,
      periodChange,
      changePercentage,
      totalChangePercentage,
      totalChange,
    };
  };

  const formatUSD = (value) => {
    return new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const formatPercentage = (value) => {
    return `${value > 0 ? "+" : ""}${value.toFixed(2)}%`;
  };

  const getPeriodLabel = () => {
    switch (selectedTimeRange) {
      case "24h":
        return "24h";
      case "1w":
        return "7d";
      case "1m":
        return "30d";
      default:
        return "24h";
    }
  };
  const formatDate = (timestamp) => {
    return format(new Date(timestamp), "d 'de' MMMM 'de' yyyy", { locale: es }).replace(/^\w/, (c) => c.toUpperCase());
  };
  return (
    <>
      <Navbar />

      <div className="bg-white rounded-xl shadow-sm border border-gray-100 mb-4">
        <div className="p-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-gray-900">Administración de Usuarios</h2>
            <button onClick={() => setShowCreateForm(!showCreateForm)} className="px-4 py-2 text-sm font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              {showCreateForm ? "Cancelar" : "Crear Usuario"}
            </button>
          </div>

          {showCreateForm && (
            <div className="mt-4 border-t pt-4">
              <h3 className="text-lg font-semibold mb-4">Crear Nuevo Usuario</h3>
              {createError && <div className="mb-4 p-4 rounded-lg bg-red-50 text-red-700">{createError}</div>}
              <form onSubmit={handleCreateUser} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Nombre</label>
                  <input type="text" name="name" value={newUser.name} onChange={handleCreateChange} required className="w-full px-3 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Apellido</label>
                  <input type="text" name="surname" value={newUser.surname} onChange={handleCreateChange} required className="w-full px-3 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                  <input type="email" name="email" value={newUser.email} onChange={handleCreateChange} required className="w-full px-3 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Contraseña</label>
                  <input type="text" name="password" value={newUser.password} onChange={handleCreateChange} required className="w-full px-3 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />
                </div>
                <div>
                  <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">
                    País
                  </label>
                  <select id="country" name="country" value={newUser.country} onChange={handleCreateChange} className="w-full px-3 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" required>
                    <option value="">Seleccionar país</option>
                    {[
                      { name: "Argentina", code: "AR", phoneCode: "+54" },
                      { name: "Bolivia", code: "BO", phoneCode: "+591" },
                      { name: "Brazil", code: "BR", phoneCode: "+55" },
                      { name: "Chile", code: "CL", phoneCode: "+56" },
                      { name: "Colombia", code: "CO", phoneCode: "+57" },
                      { name: "Ecuador", code: "EC", phoneCode: "+593" },
                      { name: "Paraguay", code: "PY", phoneCode: "+595" },
                      { name: "Peru", code: "PE", phoneCode: "+51" },
                      { name: "Uruguay", code: "UY", phoneCode: "+598" },
                      { name: "Venezuela", code: "VE", phoneCode: "+58" },
                    ].map((country) => (
                      <option key={country.code} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                    Teléfono
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      {newUser.country
                        ? [
                            { name: "Argentina", code: "AR", phoneCode: "+54" },
                            { name: "Bolivia", code: "BO", phoneCode: "+591" },
                            { name: "Brazil", code: "BR", phoneCode: "+55" },
                            { name: "Chile", code: "CL", phoneCode: "+56" },
                            { name: "Colombia", code: "CO", phoneCode: "+57" },
                            { name: "Ecuador", code: "EC", phoneCode: "+593" },
                            { name: "Paraguay", code: "PY", phoneCode: "+595" },
                            { name: "Peru", code: "PE", phoneCode: "+51" },
                            { name: "Uruguay", code: "UY", phoneCode: "+598" },
                            { name: "Venezuela", code: "VE", phoneCode: "+58" },
                          ].find((c) => c.name === newUser.country)?.phoneCode || "+"
                        : "+"}
                    </span>
                    <input type="text" id="phone" name="phone" value={newUser.phone} onChange={handleCreateChange} className="flex-1 min-w-0 block w-full rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">DNI</label>
                  <input type="text" name="personId" value={newUser.personId} onChange={handleCreateChange} className="w-full px-3 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Wallet</label>
                  <input type="text" name="wallet" value={newUser.wallet} onChange={handleCreateChange} required className="w-full px-3 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Inversión Inicial</label>
                  <input type="number" name="initialInvestment" value={newUser.initialInvestment} onChange={handleCreateChange} required className="w-full px-3 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Fecha Inversión Inicial</label>
                  <input
                    type="date"
                    name="initialInvestmentDate"
                    value={new Date(newUser.initialInvestmentDate).toISOString().split("T")[0]}
                    onChange={(e) =>
                      handleCreateChange({
                        target: {
                          name: "initialInvestmentDate",
                          value: new Date(e.target.value).getTime(),
                        },
                      })
                    }
                    required
                    className="w-full px-3 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Rol</label>
                  <select name="role" value={newUser.role} onChange={handleCreateChange} required className="w-full px-3 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                    <option value="user">Usuario</option>
                    <option value="admin">Admin</option>
                  </select>
                </div>
                <div className="md:col-span-2 lg:col-span-3 flex justify-end">
                  <button type="submit" disabled={createLoading} className={`px-6 py-2 text-sm font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${createLoading ? "opacity-50 cursor-not-allowed" : ""}`}>
                    {createLoading ? "Creando..." : "Crear Usuario"}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>

      <div className="min-h-screen bg-gray-50 py-4">
        <div className="max-w-[95%] mx-auto">
          <div className="bg-white rounded-xl shadow-sm border border-gray-100">
            <div className="overflow-x-auto">
              <table className="w-full table-fixed">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="w-32 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                    <th className="w-40 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                    <th className="w-40 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Apellido</th>
                    <th className="w-56 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                    <th className="w-32 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Wallet</th>
                    <th className="w-32 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Inversión Inicial</th>
                    <th className="w-40 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha Inv. Inicial</th>
                    <th className="w-32 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Retiro Pendiente</th>
                    <th className="w-40 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contraseña</th>
                    <th className="w-64 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {users.map((userData) => (
                    <React.Fragment key={userData._id}>
                      <tr className="hover:bg-gray-50">
                        <td className="px-3 py-2 text-xs text-gray-500 truncate" title={userData._id}>
                          {userData._id}
                        </td>
                        <td className="px-3 py-2">
                          <input type="text" value={editingUsers[userData._id]?.name} onChange={(e) => handleInputChange(userData._id, "name", e.target.value)} className="w-full px-2 py-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />
                        </td>
                        <td className="px-3 py-2">
                          <input type="text" value={editingUsers[userData._id]?.surname} onChange={(e) => handleInputChange(userData._id, "surname", e.target.value)} className="w-full px-2 py-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />
                        </td>
                        <td className="px-3 py-2">
                          <input type="email" value={editingUsers[userData._id]?.email} onChange={(e) => handleInputChange(userData._id, "email", e.target.value)} className="w-full px-2 py-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />
                        </td>
                        <td className="px-3 py-2">
                          <input type="text" value={editingUsers[userData._id]?.wallet} onChange={(e) => handleInputChange(userData._id, "wallet", e.target.value)} className="w-full px-2 py-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />
                        </td>
                        <td className="px-3 py-2">
                          <input type="number" value={editingUsers[userData._id]?.initialInvestment} onChange={(e) => handleInputChange(userData._id, "initialInvestment", parseFloat(e.target.value))} className="w-full px-2 py-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />
                        </td>
                        <td className="px-3 py-2">
                          <input type="date" value={new Date(editingUsers[userData._id]?.initialInvestmentDate || new Date().getTime()).toISOString().split("T")[0]} onChange={(e) => handleInputChange(userData._id, "initialInvestmentDate", new Date(e.target.value).getTime())} className="w-full px-2 py-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />
                        </td>
                        <td className="px-3 py-2">
                          <select value={editingUsers[userData._id]?.withdrawalPending} onChange={(e) => handleInputChange(userData._id, "withdrawalPending", e.target.value === "true")} className="w-full px-2 py-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                            <option value="false">No</option>
                            <option value="true">Si</option>
                          </select>
                        </td>
                        <td className="px-3 py-2">
                          <div className="flex gap-2">
                            <button onClick={() => togglePasswordField(userData._id)} className="px-2 py-1 text-sm font-medium rounded text-indigo-600 border border-indigo-600 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              {showPasswordFields[userData._id] ? "Cancelar" : "Cambiar"}
                            </button>
                            {showPasswordFields[userData._id] && <input type="password" value={editingUsers[userData._id]?.password || ""} onChange={(e) => handleInputChange(userData._id, "password", e.target.value)} placeholder="Nueva contraseña" className="w-full px-2 py-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />}
                          </div>
                        </td>
                        <td className="px-3 py-2">
                          <div className="flex gap-2">
                            <button onClick={() => handleUpdate(userData._id)} disabled={updateLoading[userData._id]} className={`px-3 py-1 text-sm font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${updateLoading[userData._id] ? "opacity-50 cursor-not-allowed" : ""}`}>
                              {updateLoading[userData._id] ? (
                                <span className="flex items-center justify-center">
                                  <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                </span>
                              ) : (
                                "Actualizar"
                              )}
                            </button>
                            <button onClick={() => toggleChart(userData._id)} className={`px-3 py-1 text-sm font-medium rounded text-indigo-600 border border-indigo-600 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${expandedUser === userData._id ? "bg-indigo-50" : ""}`}>
                              {expandedUser === userData._id ? "Ocultar Gráfico" : "Ver Gráfico"}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {expandedUser === userData._id && (
                        <tr>
                          <td colSpan="10" className="px-3 py-4 bg-gray-50">
                            <div className="border rounded-lg bg-white p-4">
                              <h3 className="text-lg font-semibold mb-4">
                                Balance Chart - {userData.name} {userData.surname}
                              </h3>

                              {/* Stats Cards */}
                              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                                {/* Current Balance Card */}
                                <div className="p-6 bg-gradient-to-br from-indigo-50 to-indigo-100 rounded-lg">
                                  <h3 className="text-sm font-medium text-indigo-600 mb-1">Balance Actual</h3>
                                  <p className="text-2xl font-bold text-gray-900">{formatUSD(calculateStats(userData).currentBalance)}</p>
                                </div>

                                {/* Period Change Card */}
                                {(() => {
                                  const stats = calculateStats(userData);
                                  return (
                                    <div className={`p-6 bg-gradient-to-br ${stats.changePercentage >= 0 ? "from-emerald-50 to-emerald-100" : "from-red-50 to-red-100"} rounded-lg`}>
                                      <h3 className={`text-sm font-medium ${stats.changePercentage >= 0 ? "text-emerald-600" : "text-red-600"} mb-1`}>{getPeriodLabel()} Cambio</h3>
                                      <div className="flex items-baseline gap-2">
                                        <p className="text-2xl font-bold text-gray-900">{formatPercentage(stats.changePercentage)}</p>
                                        <p className="text-sm text-gray-600">({formatUSD(stats.periodChange)})</p>
                                      </div>
                                    </div>
                                  );
                                })()}

                                {/* Total/Point Return Card */}
                                {(() => {
                                  const stats = calculateStats(userData);
                                  return (
                                    <div className={`p-6 bg-gradient-to-br ${stats.totalChangePercentage >= 0 ? "from-emerald-50 to-emerald-100" : "from-red-50 to-red-100"} rounded-lg`}>
                                      <h3 className={`text-sm font-medium ${stats.totalChangePercentage >= 0 ? "text-emerald-600" : "text-red-600"} mb-1`}>{hoverData ? "Retorno en Punto Seleccionado" : "Retorno Total"}</h3>
                                      <div className="flex items-baseline gap-2">
                                        <p className="text-2xl font-bold text-gray-900">{formatPercentage(stats.totalChangePercentage)}</p>
                                        <p className="text-sm text-gray-600">({formatUSD(stats.totalChange)})</p>
                                      </div>
                                    </div>
                                  );
                                })()}
                              </div>

                              <BalanceChart
                                balanceHistory={userData.balance || []}
                                initialInvestment={{
                                  amount: userData.initialInvestment,
                                  timestamp: userData.initialInvestmentDate, // Convert to seconds
                                }}
                                onHoverChange={setHoverData}
                                onTimeRangeChange={setSelectedTimeRange}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
