import React, { useState, useMemo } from "react";
import { useAuth } from "../context/AuthContext";
import BalanceChart from "./BalanceChart";
import { format, subHours, subWeeks, subMonths } from "date-fns";
import { es } from "date-fns/locale";

const Dashboard = () => {
  const { user } = useAuth();
  const [hoverData, setHoverData] = useState(null);
  const [balanceFormatted, setBalanceFormatted] = useState([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState("24h");

  // Modified balance with 10000 added to the last entry if email matches
  const modifiedBalance = useMemo(() => {
    if (!user?.balance) return [];

    // Clone and modify the balance if email matches
    const newBalance = [...user.balance];
    if (user.email === "christian@gruporift.com" && newBalance.length > 0) {
      newBalance[newBalance.length - 1] = {
        ...newBalance[newBalance.length - 1],
        totalUSD: newBalance[newBalance.length - 1].totalUSD + 10000,
      };
    }

    return newBalance;
  }, [user?.balance, user?.email]);

  const initialInvestment = useMemo(() => {
    if (!user) return null;

    return {
      amount: user.initialInvestment || 0,
      timestamp: (user.initialInvestmentDate || new Date().getTime()) / 1000,
    };
  }, [user]);

  const stats = useMemo(() => {
    if (modifiedBalance.length === 0 || !initialInvestment) {
      return {
        currentBalance: 0,
        periodChange: 0,
        changePercentage: 0,
        totalChangePercentage: 0,
        totalChange: 0,
      };
    }

    const sortedBalance = modifiedBalance
      .filter((bal) => bal.wallet.toLowerCase() === user.wallet.toLowerCase())
      .sort((a, b) => a.timestamp - b.timestamp);

    if (sortedBalance.length === 0) {
      setBalanceFormatted([]);
      return {
        currentBalance: 0,
        periodChange: 0,
        changePercentage: 0,
        totalChangePercentage: 0,
        totalChange: 0,
      };
    }

    setBalanceFormatted(sortedBalance);
    const currentBalance = sortedBalance[sortedBalance.length - 1].totalUSD;

    const balanceToCompare = hoverData ? hoverData.value : currentBalance;

    const totalChange = balanceToCompare - initialInvestment.amount;
    const totalChangePercentage = (totalChange / initialInvestment.amount) * 100;

    let periodStartTimestamp;
    switch (selectedTimeRange) {
      case "24h":
        periodStartTimestamp = subHours(new Date(), 24).getTime() / 1000;
        break;
      case "1w":
        periodStartTimestamp = subWeeks(new Date(), 1).getTime() / 1000;
        break;
      case "1m":
        periodStartTimestamp = subMonths(new Date(), 1).getTime() / 1000;
        break;
      default:
        periodStartTimestamp = subHours(new Date(), 24).getTime() / 1000;
    }

    const periodData = sortedBalance.filter((b) => b.timestamp >= periodStartTimestamp);

    let periodChange = 0;
    let changePercentage = 0;

    if (periodData.length >= 2) {
      periodChange = currentBalance - periodData[0].totalUSD;
      changePercentage = (periodChange / periodData[0].totalUSD) * 100;
    }

    return {
      currentBalance,
      periodChange,
      changePercentage,
      totalChangePercentage,
      totalChange,
    };
  }, [modifiedBalance, hoverData, initialInvestment, selectedTimeRange]);

  const formatUSD = (value) => {
    return new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const formatPercentage = (value) => {
    return `${value > 0 ? "+" : ""}${value.toFixed(2)}%`;
  };

  const formatDate = (timestamp) => {
    return format(timestamp * 1000, "d 'de' MMMM 'de' yyyy", { locale: es }).replace(/^\w/, (c) => c.toUpperCase()); // Capitalize first letter
  };

  const getPeriodLabel = () => {
    switch (selectedTimeRange) {
      case "24h":
        return "24h";
      case "1w":
        return "7d";
      case "1m":
        return "30d";
      default:
        return "24h";
    }
  };

  if (!user?.balance || !initialInvestment) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid gap-6">
          {/* Stats Cards */}
          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
            <div className="flex items-baseline gap-2">
              <span className="text-sm text-gray-500">Inversión Inicial:</span>
              <span className="font-medium">{formatUSD(initialInvestment.amount)}</span>
              <span className="text-sm text-gray-500">el</span>
              <span className="font-medium">{formatDate(initialInvestment.timestamp)}</span>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* Current Balance Card */}
              <div className="p-6 bg-gradient-to-br from-indigo-50 to-indigo-100 rounded-lg">
                <h3 className="text-sm font-medium text-indigo-600 mb-1">Balance Actual</h3>
                <p className="text-2xl font-bold text-gray-900">{formatUSD(stats.currentBalance)}</p>
              </div>

              {/* Period Change Card */}
              <div className={`p-6 bg-gradient-to-br ${stats.changePercentage >= 0 ? "from-emerald-50 to-emerald-100" : "from-red-50 to-red-100"} rounded-lg`}>
                <h3 className={`text-sm font-medium ${stats.changePercentage >= 0 ? "text-emerald-600" : "text-red-600"} mb-1`}>{getPeriodLabel()} Cambio</h3>
                <div className="flex items-baseline gap-2">
                  <p className="text-2xl font-bold text-gray-900">{formatPercentage(stats.changePercentage)}</p>
                  <p className="text-sm text-gray-600">({formatUSD(stats.periodChange)})</p>
                </div>
              </div>

              {/* Total/Point Return Card */}
              <div className={`p-6 bg-gradient-to-br ${stats.totalChangePercentage >= 0 ? "from-emerald-50 to-emerald-100" : "from-red-50 to-red-100"} rounded-lg transition-colors duration-200`}>
                <h3 className={`text-sm font-medium ${stats.totalChangePercentage >= 0 ? "text-emerald-600" : "text-red-600"} mb-1`}>{hoverData ? "Retorno en Punto Seleccionado" : "Retorno Total"}</h3>
                <div className="flex items-baseline gap-2">
                  <p className="text-2xl font-bold text-gray-900">{formatPercentage(stats.totalChangePercentage)}</p>
                  <p className="text-sm text-gray-600">({formatUSD(stats.totalChange)})</p>
                </div>
              </div>
            </div>
          </div>

          {/* Chart */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-100">
            <BalanceChart balanceHistory={balanceFormatted || []} initialInvestment={initialInvestment} onHoverChange={setHoverData} onTimeRangeChange={setSelectedTimeRange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
