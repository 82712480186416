import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Profile from "./components/Profile";
import Withdrawals from "./components/Withdrawals";
import Navbar from "./components/Navbar";
import Admin from "./components/Admin";
import { AuthProvider, useAuth } from "./context/AuthContext";

// Protected Route wrapper component
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

// Public Route wrapper component (redirects to dashboard if already logged in)
const PublicRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

const AppRoutes = () => (
  <Routes>
    <Route path="/admin" element={<Admin />} />
    <Route
      path="/login"
      element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      }
    />
    <Route
      path="/dashboard"
      element={
        <ProtectedRoute>
          <div className="min-h-screen bg-gray-100">
            <Navbar />
            <Dashboard />
          </div>
        </ProtectedRoute>
      }
    />
    <Route
      path="/profile"
      element={
        <ProtectedRoute>
          <div className="min-h-screen bg-gray-100">
            <Navbar />
            <Profile />
          </div>
        </ProtectedRoute>
      }
    />
    <Route
      path="/withdrawals"
      element={
        <ProtectedRoute>
          <div className="min-h-screen bg-gray-100">
            <Navbar />
            <Withdrawals />
          </div>
        </ProtectedRoute>
      }
    />
    <Route path="/" element={<Navigate to="/dashboard" replace />} />
  </Routes>
);

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
}

export default App;
