import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Menu, X } from "lucide-react";

const Navbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <span className="text-xl font-bold text-indigo-600">Grupo Rift</span>
            </div>
            <div className="hidden md:ml-6 md:flex md:space-x-8">
              <Link to="/dashboard" className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${location.pathname === "/dashboard" ? "border-indigo-500 text-gray-900" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}>
                Dashboard
              </Link>
              <Link to="/profile" className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${location.pathname === "/profile" ? "border-indigo-500 text-gray-900" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}>
                Perfil
              </Link>
              <Link to="/withdrawals" className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${location.pathname === "/withdrawals" ? "border-indigo-500 text-gray-900" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}>
                Retiros
              </Link>
              {user?.role === "admin" && (
                <Link to="/admin" className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200 ${location.pathname === "/admin" ? "bg-indigo-700" : ""}`}>
                  Admin
                </Link>
              )}
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className="hidden md:flex items-center gap-2">
              <div className="h-8 w-8 rounded-full bg-indigo-100 flex items-center justify-center">
                <span className="text-sm font-medium text-indigo-600">{user?.email?.[0]?.toUpperCase()}</span>
              </div>
              <span className="text-sm text-gray-700">{user?.email}</span>
            </div>
            <button onClick={handleLogout} className="hidden md:inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200">
              Cerrar sesion
            </button>

            {/* Mobile menu button */}
            <button onClick={() => setIsOpen(!isOpen)} className="md:hidden inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        {isOpen && (
          <div className="md:hidden">
            <div className="pt-2 pb-3 space-y-1">
              <Link to="/dashboard" className={`block pl-3 pr-4 py-2 text-base font-medium ${location.pathname === "/dashboard" ? "bg-indigo-50 border-l-4 border-indigo-500 text-indigo-700" : "text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"}`} onClick={() => setIsOpen(false)}>
                Dashboard
              </Link>
              <Link to="/profile" className={`block pl-3 pr-4 py-2 text-base font-medium ${location.pathname === "/profile" ? "bg-indigo-50 border-l-4 border-indigo-500 text-indigo-700" : "text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"}`} onClick={() => setIsOpen(false)}>
                Perfil
              </Link>
              <Link to="/withdrawals" className={`block pl-3 pr-4 py-2 text-base font-medium ${location.pathname === "/withdrawals" ? "bg-indigo-50 border-l-4 border-indigo-500 text-indigo-700" : "text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"}`} onClick={() => setIsOpen(false)}>
                Retiros
              </Link>
              {user?.role === "admin" && (
                <Link to="/admin" className={`block pl-3 pr-4 py-2 text-base font-medium ${location.pathname === "/admin" ? "bg-indigo-50 border-l-4 border-indigo-500 text-indigo-700" : "text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"}`} onClick={() => setIsOpen(false)}>
                  Admin
                </Link>
              )}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <div className="h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center">
                    <span className="text-sm font-medium text-indigo-600">{user?.email?.[0]?.toUpperCase()}</span>
                  </div>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">{user?.email}</div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                <button
                  onClick={() => {
                    handleLogout();
                    setIsOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50"
                >
                  Cerrar sesion
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
