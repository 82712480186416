import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import axiosInstance from "../utils/axiosInstance";

const Profile = () => {
  const { user, fetchUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [selectedCountryCode, setSelectedCountryCode] = useState("");

  // Country data with phone codes
  const countries = [
    { name: "Argentina", code: "AR", phoneCode: "+54" },
    { name: "Bolivia", code: "BO", phoneCode: "+591" },
    { name: "Brazil", code: "BR", phoneCode: "+55" },
    { name: "Chile", code: "CL", phoneCode: "+56" },
    { name: "Colombia", code: "CO", phoneCode: "+57" },
    { name: "Ecuador", code: "EC", phoneCode: "+593" },
    { name: "Paraguay", code: "PY", phoneCode: "+595" },
    { name: "Peru", code: "PE", phoneCode: "+51" },
    { name: "Uruguay", code: "UY", phoneCode: "+598" },
    { name: "Venezuela", code: "VE", phoneCode: "+58" },
    // Add more countries as needed
  ];

  const [formData, setFormData] = useState({
    country: user?.country || "",
    name: user?.name || "",
    surname: user?.surname || "",
    phone: user?.phone || "",
    personId: user?.personId || "",
    email: user?.email || "",
  });

  useEffect(() => {
    // Set initial country code based on user's country
    if (formData.country) {
      const country = countries.find((c) => c.name === formData.country);
      if (country) {
        setSelectedCountryCode(country.phoneCode);
      }
    }
  }, [formData.country]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "country") {
      const selectedCountry = countries.find((c) => c.name === value);
      if (selectedCountry) {
        setSelectedCountryCode(selectedCountry.phoneCode);
      }
    }

    if (name === "phone") {
      // Remove any existing phone code from the input
      const phoneNumber = value.replace(/^\+\d+\s*/, "");
      setFormData((prev) => ({
        ...prev,
        [name]: phoneNumber,
      }));
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: "", text: "" });

    try {
      // Combine phone code with phone number for submission
      const submissionData = {
        ...formData,
        phone: formData.phone ? `${selectedCountryCode} ${formData.phone}` : "",
      };

      await axiosInstance.put("/v1/users", submissionData);
      await fetchUser();
      setMessage({ type: "success", text: "Perfil actualizado con exito!" });
    } catch (error) {
      setMessage({
        type: "error",
        text: error.response?.data?.message || "Error actualizando perfil",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-xl shadow-sm p-8 border border-gray-100">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Configuracion Perfil</h2>

          {message.text && <div className={`mb-4 p-4 rounded-lg ${message.type === "success" ? "bg-green-50 text-green-700" : "bg-red-50 text-red-700"}`}>{message.text}</div>}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Nombre
              </label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
            </div>

            <div>
              <label htmlFor="surname" className="block text-sm font-medium text-gray-700">
                Apellido
              </label>
              <input type="text" id="surname" name="surname" value={formData.surname} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input type="email" id="email" name="email" value={formData.email} disabled className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 cursor-not-allowed" />
            </div>

            <div>
              <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                Pais
              </label>
              <select id="country" name="country" value={formData.country} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500">
                <option value="">Seleccionar país</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Telefono
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">{selectedCountryCode || "+"}</span>
                <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} className="flex-1 min-w-0 block w-full rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500" />
              </div>
            </div>

            <div>
              <label htmlFor="personId" className="block text-sm font-medium text-gray-700">
                DNI
              </label>
              <input type="text" id="personId" name="personId" value={formData.personId} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" />
            </div>

            <div className="flex justify-end">
              <button type="submit" disabled={loading} className={`px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${loading ? "opacity-50 cursor-not-allowed" : ""}`}>
                {loading ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Actualizando...
                  </span>
                ) : (
                  "Actualizar"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
