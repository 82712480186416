import React, { useState, useMemo } from "react";
import { useAuth } from "../context/AuthContext";
import axiosInstance from "../utils/axiosInstance";

const ACCOUNT_TYPES = [
  { value: "Corriente", label: "Cuenta Corriente" },
  { value: "Vista", label: "Cuenta Vista" },
];

const WITHDRAWAL_TYPES = [
  {
    value: "standard",
    label: "Estándar - 72 horas hábiles",
    description: "Se procesa dentro de 72 horas hábiles",
    fee: 0,
  },
  {
    value: "express",
    label: "Express - 12 horas hábiles",
    description: "Se procesa dentro de las próximas 12 horas hábiles",
    fee: 0.01, // 1%
  },
];

const BANKS = [
  { value: "banco_chile", label: "Banco de Chile" },
  { value: "banco_santander", label: "Banco Santander" },
  { value: "bci", label: "Banco de crédito e inversiones (BCI)" },
  { value: "banco_estado", label: "Banco Estado" },
  { value: "banco_internacional", label: "Banco Internacional" },
  { value: "banco_itau", label: "Banco Itaú" },
  { value: "banco_ripley", label: "Banco Ripley" },
  { value: "scotiabank", label: "Scotiabank" },
  { value: "banco_falabella", label: "Banco Falabella" },
  { value: "coopeuch", label: "Coopeuch" },
  { value: "banco_bice", label: "Banco Bice" },
  { value: "banco_consorcio", label: "Banco Consorcio" },
];

const Retiros = () => {
  const { user, fetchUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    amount: "",
    bankAccType: "",
    bankName: "",
    bank: "",
    withdrawalType: "", // New field for withdrawal type
  });

  const totalAmount = useMemo(() => {
    if (!formData.amount) return 0;
    const blockchainFee = 20; // Flat $20 fee
    const percentageFee = formData.withdrawalType === "express" ? Number(formData.amount) * 0.01 : 0;
    const totalFees = blockchainFee + percentageFee;
    return Number(formData.amount) - totalFees;
  }, [formData.amount, formData.withdrawalType]);

  const feeAmount = useMemo(() => {
    if (!formData.amount || !formData.withdrawalType) return 0;
    const blockchainFee = 20;
    const selectedType = WITHDRAWAL_TYPES.find((type) => type.value === formData.withdrawalType);
    const percentageFee = selectedType ? Number(formData.amount) * selectedType.fee : 0;
    return blockchainFee + percentageFee;
  }, [formData.amount, formData.withdrawalType]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setHasSubmitted(true);

    try {
      const submissionData = {
        ...formData,
        bankLabel: BANKS.find((b) => b.value === formData.bank)?.label || formData.bank,
        feeAmount,
        totalAmount,
      };

      await axiosInstance.put("/v1/users/initiateWithdrawal", submissionData);
      setMessage({ type: "success", text: "Solicitud de retiro enviada exitosamente!" });
      setFormData({
        amount: "",
        bankAccType: "",
        bankName: "",
        bank: "",
        withdrawalType: "",
      });
      await fetchUser();
    } catch (error) {
      setMessage({
        type: "error",
        text: error.response?.data?.message || "Error al enviar la solicitud de retiro",
      });
      setHasSubmitted(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-xl shadow-sm p-8 border border-gray-100">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Solicitud de Retiro</h2>

          {message.text && <div className={`mb-4 p-4 rounded-lg ${message.type === "success" ? "bg-green-50 text-green-700" : "bg-red-50 text-red-700"}`}>{message.text}</div>}

          {user?.withdrawalPending && !hasSubmitted && <div className="mb-6 p-4 rounded-lg bg-yellow-50 text-yellow-700">Ya tienes una solicitud de retiro pendiente.</div>}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="withdrawalType" className="block text-sm font-medium text-gray-700">
                Tipo de Retiro
              </label>
              <div className="mt-2 space-y-4">
                {WITHDRAWAL_TYPES.map((type) => (
                  <div key={type.value} className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input id={type.value} name="withdrawalType" type="radio" value={type.value} checked={formData.withdrawalType === type.value} onChange={handleChange} className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500" required />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor={type.value} className="font-medium text-gray-700">
                        {type.label}
                      </label>
                      <p className="text-gray-500">{type.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                Monto
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input type="number" id="amount" name="amount" value={formData.amount} onChange={handleChange} className="mt-1 block w-full pl-7 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" placeholder="0.00" min="0" step="0.01" required />
              </div>
              {formData.amount && (
                <div className="mt-2 text-sm">
                  <p className="text-gray-600">Gastos de blockchain: $20</p>
                  {formData.withdrawalType === "express" && <p className="text-gray-600">Comision express (1%): ${(Number(formData.amount) * 0.01).toLocaleString("es-CL", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>}
                  <p className="text-gray-600">Total en comisiones: ${feeAmount.toLocaleString("es-CL", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                  <p className="font-medium text-gray-700">Total a recibir: ${totalAmount.toLocaleString("es-CL", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </div>
              )}
            </div>

            <div>
              <label htmlFor="bank" className="block text-sm font-medium text-gray-700">
                Banco
              </label>
              <select id="bank" name="bank" value={formData.bank} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" required>
                <option value="">Seleccionar banco</option>
                {BANKS.map((bank) => (
                  <option key={bank.value} value={bank.value}>
                    {bank.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="bankAccType" className="block text-sm font-medium text-gray-700">
                Tipo de cuenta
              </label>
              <select id="bankAccType" name="bankAccType" value={formData.bankAccType} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" required>
                <option value="">Seleccionar tipo de cuenta</option>
                {ACCOUNT_TYPES.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="bankName" className="block text-sm font-medium text-gray-700">
                Número de cuenta
              </label>
              <input type="text" id="bankName" name="bankName" value={formData.bankName} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500" required />
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={loading || user?.withdrawalPending}
                className={`px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200
                  ${loading || user?.withdrawalPending ? "opacity-50 cursor-not-allowed" : ""}`}
              >
                {loading ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Procesando...
                  </span>
                ) : (
                  "Pedir Retiro"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Retiros;
